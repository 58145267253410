/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import 'lightgallery/scss/lightgallery';
.app.blue header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.blue header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
    color: #000;
}
@media (min-width:px)  {
    header.has-bg-image:not(.main-toolbar-fixed) #main-toolbar{
   background: #ffffff;
   
   }
   }
.pac-container {
    display: none!important;
}
.app.blue .mat-table {
    background: #f7f7f7;
}
tr.mat-header-row {
    height: 56px;
    background-color: #303f9f!important;
    vertical-align: middle;
}
tr.mat-header-row th {
    color: #ffffff!important;
    padding-left: 22px;
}
tbody tr td {
    vertical-align: middle;
    padding-left: 20px!important;
}
html body .pac-container {
    display: block!important;
    z-index: 10000 !important;
}
iframe#widget2 {
    width: 100%!important;
}
.mat-form-field-required-marker { color: red }
#main-toolbar {
    z-index: 99!important;
}
body .mg-info-author-block{
    align-items: self-end!important;
}