$font-family: 'Montserrat';
$theme-max-width: 1300px;
$top-toolbar-height: 36px;
$main-toolbar-height: 72px;
$user-menu-width: 220px;
$header-image-min-height: 320px;
$header-video-min-height: 360px;
$header-carousel-height: 460px;
$header-map-height: 550px;
$main-content-header-offset-to-top: 80px;

$main-sidenav-width: 250px;
$search-sidenav-width: 288px;